<template>
	<div class="box">
		<case-details @getCaseDetailsData="getCaseDetailsData">
			<template slot="titleBtn">
				<div v-if="$route.query.from === '/home/insuranceRenewal/renewalList'" class="action-btn">
					<a-button type="primary" @click="submitReview">{{ $t('operate.sendAudit') }}</a-button>
				</div>
				<div v-if="$route.query.from === '/home/insuranceRenewal/renewalReview'" class="action-btn">
					<comments-popover />
					<a-popover v-model="recordPopoverShow" trigger="click" placement="topLeft">
						<a-row slot="title" class="comments-title">
							<a-col :span="16"> {{ $t('table.loanReview.record') }} </a-col>
							<a-col :span="8" style="text-align: right">
								<a-icon @click="recordPopoverShow = false" type="close" />
							</a-col>
						</a-row>
						<div slot="content" class="record-content">
							<record :loanPreCreditResponse="loanPreCreditResponse" :checkRecords="checkRecords"></record>
						</div>
						<a-button><img class="icon-btn" src="@/assets/Record.png" />{{ $t('table.loanReview.record') }} </a-button>
					</a-popover>
					<loan-brief v-if="loanBriefListData" :loanBriefListData="loanBriefListData" />
					<submit-review
						v-if="processStatus"
						:processStatus="processStatus"
						:loanPreCreditResponse="loanPreCreditResponse"
						:checkRecords="checkRecords"
						:insuranceRenewal="insuranceRenewal"
					/>
				</div>
				<span v-if="$route.query.from === '/home/insuranceRenewal/renewalPending'" class="row-start-center f-1">
					<!-- <a-button type="primary" :disabled="data.loanInfo?.disburseType !== 'choice'" @click="toDisburseNow"> Disburse Now </a-button>
					<a-button type="primary" style="margin-left: 10px" @click="toRecordDisbursementEntries"> Record Disbursement Entries </a-button> -->
					<div class="f-1" />
					<pass-disburse-record-modal
						:loanId="detailData.id"
						:loanType="detailData.loanType"
						:processNodeId="detailData.processStatus?.processNode?.id"
						listPagePath="renewalPending"
					>
						<a-button
							slot="reference"
							type="primary"
							:disabled="!$hasPermission('ROLE_RENEWAL_PENDING_CHECKER')"
							:class="{ 'btn-pass': $hasPermission('ROLE_RENEWAL_PENDING_CHECKER') }"
						>
							{{ $t('operate.comfireAmount') }}
						</a-button>
					</pass-disburse-record-modal>
					<a-button
						type="primary"
						:disabled="!($hasPermission('ROLE_RENEWAL_PENDING_MAKER') || $hasPermission('ROLE_RENEWAL_PENDING_CHECKER'))"
						:class="{ 'btn-reject': $hasPermission('ROLE_RENEWAL_PENDING_MAKER') && $hasPermission('ROLE_RENEWAL_PENDING_CHECKER') }"
						style="margin-left: 10px"
						@click="modalFail.show = true"
					>
						{{ $t('operate.auditFail') }}
					</a-button>
				</span>
			</template>
		</case-details>
		<!-- 确认放款 -->
		<a-modal v-drag-modal :title="$t('operate.comfireAmount')" width="800px" v-model="modalAmount.show" :footer="false">
			<a-form-model ref="ruleForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
				<div v-for="(item, idx) in modalAmount.payList" :key="idx">
					<a-form-model-item>
						{{ $t('table.applyInfo.amountCloums.pay') + (idx + 1) }}
					</a-form-model-item>
					<a-form-model-item :label="$t('table.applyInfo.amountCloums.payName')" prop="disburseName">
						<a-input v-model="item.disburseName" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.applyInfo.amountCloums.payAccount')" prop="disburseAccount">
						<a-input v-model="item.disburseAccount" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.applyInfo.amountCloums.payType')" prop="disburseType">
						<a-select v-model="item.disburseType">
							<a-select-option :key="items" :value="items" v-for="items in ['Stanbic', 'DTB']">{{ items }}</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.applyInfo.amountCloums.payAmount')" prop="disburseAmount">
						<a-input-number v-model="item.disburseAmount" :precision="0" />
					</a-form-model-item>
					<a-form-model-item :label="$t('table.applyInfo.amountCloums.payTime')" prop="paidTime">
						<a-date-picker
							:format="dateFormat"
							showTime
							type="date"
							v-model="item.paidTime"
							:disabled-date="
								(startValue) => {
									return startValue.valueOf() > moment().subtract(0, 'days').valueOf()
								}
							"
						/>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.Disbursement-Receipt')">
						<UploadPreviewFile :uploadFilePath.sync="item.disburseCertificateFile" :folderPath="$route.query.id"></UploadPreviewFile>
					</a-form-model-item>
				</div>
				<div style="margin: 20px 0 0 340px">
					<a-button type="primary" @click="setModalAmount(1)">{{ $t('table.applyInfo.amountCloums.addPay') }}</a-button>
					<a-button type="primary" @click="setModalAmount(0)" style="margin-left: 20px">{{ $t('table.applyInfo.amountCloums.deletePay') }}</a-button>
					<a-button type="primary" @click="comfireAmount" style="margin-left: 20px">
						{{ $t('operate.save') }}
					</a-button>
					<a-button @click="cncelModalAmount" style="margin-left: 20px">
						{{ $t('operate.cancel') }}
					</a-button>
				</div>
			</a-form-model>
		</a-modal>
		<!-- 审核不通过 -->
		<a-modal v-drag-modal :title="$t('table.applyInfo.titleMap.title2')" width="800px" v-model="modalFail.show" @ok="submitFail">
			<a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
				<a-form-model-item :label="$t('table.loanReview.Seeting.modal.remark')">
					<a-input style="height: 100px" v-model="modalFail.checkText" type="textarea" :maxLength="300" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import { apiProcessCheck } from '@/api/check'
import CommentsPopover from '@/views/loanReview/components/CommentsPopover'
import LoanBrief from '@/views/loanReview/components/LoanBrief'
import SubmitReview from './SubmitReview'
import Record from '@/views/loanReview/components/Record'
import CaseDetails from './CaseDetails.vue'
import PassDisburseRecordModal from '@/components/disburse/pass-disburse-record-modal.vue'

export default {
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			modalAmount: {
				show: false,
				payList: []
			},
			modalFail: {
				show: false,
				checkText: '',
				checkFailType: 0
			},
			caseDetailsData: {},
			checkRecords: null,
			processStatus: null,
			loanPreCreditResponse: null,
			loanBriefListData: null,
			recordPopoverShow: false,
			insuranceRenewal: false,
			detailData: {}
		}
	},
	components: {
		CommentsPopover,
		LoanBrief,
		SubmitReview,
		Record,
		CaseDetails,
		PassDisburseRecordModal
	},
	methods: {
		moment: moment,
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', this.$route.query.from)
		},
		getCaseDetailsData(val) {
			this.caseDetailsData = val
			this.loanBriefListData = {
				basicFullName: val.personalInfo.basicFullName,
				mobiles: val.personalInfo.mobiles,
				salesmanName: val.salesmanName,
				incomeSource: val.personalInfo.incomeSource,
				loanUse: val.personalInfo.loanUse,
				loanCarInfos: val.loanCarInfos,
				applyAmount: val.loanApplyInfo.applyAmount
			}
			this.checkRecords = val.checkRecords
			this.loanPreCreditResponse = val.loanPreCreditResponse
			this.processStatus = val.processStatus
			if (
				this.caseDetailsData.loanInsuranceInfos.findIndex((insur) => {
					return insur.newInput === true && insur.hasInsurance === 1
				}) > -1
			) {
				this.insuranceRenewal = true
			}
			this.detailData = val
		},
		submitReview() {
			if (
				this.caseDetailsData.loanInsuranceInfos.findIndex((insur) => {
					return insur.newInput === true
				}) === -1
			) {
				this.$message.error('IPF Empty')
				return false
			}
			let isEmpty = false
			this.caseDetailsData.disburseInfo.carInfos.forEach((carInfo) => {
				carInfo.endorsements.forEach((endor) => {
					if (endor.newInput === true && this.$Util.isEmpty(endor.expireDate)) {
						isEmpty = true
					}
				})
			})
			if (isEmpty) {
				this.$message.error('Expiry Date Empty')
				return false
			}
			this.$confirm({
				content: 'Please check carefully to maske sure.It will be submitted to go through review process',
				onOk: () => {
					apiProcessCheck({
						loanId: this.$route.query.id,
						loanType: 'ipf_renewal',
						pass: 1,
						checkType: 'submit',
						checkText: '',
						nodeId: this.processStatus.processNode.id
					}).then((res) => {
						this.$message.success('success')
						this.closeTab(this.$route.fullPath)
						this.$router.push(this.$route.query.from)
					})
				},
				onCancel() {}
			})
		},
		checkDisburseAmount() {
			return (
				this.totalDisburseAmount ===
				this.modalAmount.payList.reduce((pre, cur) => {
					return pre + cur.principalDue
				}, 0)
			)
		},
		showModalAmount() {
			this.modalAmount = {
				show: true,
				payList: [
					{
						disburseName: '',
						disburseAccount: '',
						disburseType: '',
						disburseAmount: '',
						disburseCertificateFile: '',
						paidTime: null
					}
				]
			}
		},
		cncelModalAmount() {
			this.modalAmount.show = false
		},
		setModalAmount(type) {
			type === 1
				? this.modalAmount.payList.push({
						disburseName: '',
						disburseAccount: '',
						disburseType: '',
						disburseAmount: '',
						disburseCertificateFile: '',
						paidTime: null
				  })
				: this.modalAmount.payList.length === 1
				? ''
				: this.modalAmount.payList.pop()
		},
		comfireAmount() {
			let flag = true
			for (let index = 0; index < this.modalAmount.payList.length; index++) {
				let element = this.modalAmount.payList[index]
				delete element.url
				delete element.fileName
				if (!element.disburseName) {
					this.$message.error('pls input disburseName')
					flag = false
					break
				} else if (!element.disburseAccount) {
					this.$message.error('pls input disburseAccount')
					flag = false
					break
				} else if (!element.disburseType) {
					this.$message.error('pls input disburseType')
					flag = false
					break
				} else if (element.disburseAmount === '') {
					this.$message.error('pls input disburseAmount')
					flag = false
					break
				} else if (!element.paidTime) {
					this.$message.error('Please choose time')
					flag = false
					break
				} else if (!element.disburseCertificateFile) {
					this.$message.error('Please upload file')
					flag = false
					break
				}
				element.paidTime = this.moment(element.paidTime).format('YYYY-MM-DD HH:mm:ss')
			}
			if (!flag) {
				return false
			}
			if (this.checkDisburseAmount()) {
				this.$message.error("The disbursed amount doesn't match with the net amount.Please check again.")
				return false
			}
			apiProcessCheck({
				loanId: this.$route.query.id,
				pass: 1,
				loanType: 'ipf_renewal',
				checkType: 'check',
				checkText: 'Passed',
				nodeId: this.processStatus.processNode.id,
				attachment: this.modalAmount.payList
			}).then(() => {
				this.$message.success('success')
				this.closeTab(this.$route.fullPath)
				this.$router.push(this.$route.query.from)
			})
		},
		submitFail() {
			if (this.modalFail.checkText == '') {
				this.$message.error('remark is required')
				return false
			}
			let param = {
				loanId: this.$route.query.id,
				pass: 0,
				loanType: 'ipf_renewal',
				checkType: 'check',
				checkText: this.modalFail.checkText,
				nodeId: this.processStatus.processNode.id
			}
			apiProcessCheck(param).then((res) => {
				this.$message.success('success')
				this.closeTab(this.$route.fullPath)
				this.$router.push(this.$route.query.from)
			})
		}
		// toDisburseNow() {
		// 	this.$router.push({
		// 		path: 'disburseNow',
		// 		query: {
		// 			loanId: this.detailData.id,
		// 			loanType: this.detailData.loanType
		// 		}
		// 	})
		// },
		// toRecordDisbursementEntries() {
		// 	this.$router.push({
		// 		path: 'recordDisbursementEntries',
		// 		query: {
		// 			loanId: this.detailData.id,
		// 			loanType: this.detailData.loanType,
		// 			disburseType: this.detailData.loanInfo.disburseType || 'dtb'
		// 		}
		// 	})
		// }
	}
}
</script>
<style scoped lang="less">
.tips.ant-btn-background-ghost.ant-btn-danger {
	color: green;
	border-color: green;
}
.btn-pass {
	background-color: #67c23a;
	border-color: #67c23a;
}
.btn-reject {
	background-color: #e6a23c;
	border-color: #e6a23c;
}
</style>
