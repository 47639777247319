<template>
	<div class="case-details">
		<slot name="pageBtn"></slot>
		<user-info v-if="userInfo && processStatus.statusCode !== 'disburse_success'" :userInfo.sync="userInfo" :loanInfo="loanInfo">
			<template slot="titleBtn">
				<slot name="titleBtn" :loanInfo="loanInfo"></slot>
			</template>
		</user-info>
		<loan-info-detail :loanInfo="loanInfo" :loanCarInfos="loanCarInfos"  />
		<insur-info
			v-if="dataComplete"
			:detailData="detailData"
			:insuranceData="insuranceData"
			:isEditable="editable"
			@setInsuranceCompleted="setInsuranceCompleted"
			:statusCode.sync="this.processStatus.processNode.id"
		></insur-info>
		<create-info-detail ref="createInfo" :createInfo="createInfo" />
		<a-tabs v-if="dataComplete">
			<a-tab-pane key="1" :tab="$t('table.applyInfo.tabsMenu.personal')">
				<person-info-detail :personalInfo="personalInfo"></person-info-detail>
			</a-tab-pane>
			<a-tab-pane key="2" :tab="$t('table.applyInfo.tabsMenu.vehicle')">
				<vehicle-info :loanCarInfos="loanCarInfos"></vehicle-info>
			</a-tab-pane>
			<a-tab-pane key="3" :tab="$t('table.applyInfo.tabsMenu.financial')">
				<financial-info :financeInfo="financeInfo"></financial-info>
			</a-tab-pane>
			<a-tab-pane key="4" :tab="$t('table.applyInfo.tabsMenu.application')">
				<application-info :loanApplyInfo="loanApplyInfo"></application-info>
			</a-tab-pane>
			<a-tab-pane key="5" :tab="$t('table.applyInfo.applicationLabel.creditFile')">
				<credit-file-info :creditReport="creditReport" :basicIdentityCard="personalInfo.basicIdentityCard"></credit-file-info>
			</a-tab-pane>
			<a-tab-pane key="6" v-if="disburseInfo" :tab="$t('table.applyInfo.tabsMenu.loanDetail')">
				<loan-detail :disburseInfo="disburseInfo"></loan-detail>
			</a-tab-pane>
		</a-tabs>
		<loan-record v-if="disburseInfo && disburseInfo.disburseCertificateFile" :disburseInfo="disburseInfo"></loan-record>
		<audit-record v-if="checkHistories.length > 0" :checkHistories="checkHistories"></audit-record>
	</div>
</template>
<script>
import UserInfo from '../businessComponents/UserInfo'
import LoanInfoDetail from '../businessComponents/LoanInfoDetail'
import InsurInfo from '../businessComponents/InsurInfo'
import CreateInfoDetail from '../businessComponents/CreateInfoDetail'
import PersonInfoDetail from '../businessComponents/PersonInfoDetail'
import VehicleInfo from '../businessComponents/VehicleInfo'
import FinancialInfo from '../businessComponents/FinancialInfo'
import ApplicationInfo from '../businessComponents/ApplicationInfo'
import CreditFileInfo from '../businessComponents/CreditFileInfo'
import LoanDetail from '../businessComponents/LoanDetail'
import LoanRecord from '../businessComponents/LoanRecord'
import AuditRecord from '../businessComponents/AuditRecord'

import { apiIpfApplyDetail } from '@/api/insuranceRenewal'

export default {
	data() {
		return {
			userInfo: {},
			loanInfo: {},
			createInfo: {},
			insuranceData: [],
			personalInfo: {},
			financeInfo: {},
			loanApplyInfo: {},
			loanCarInfos: {},
			disburseInfo: {},
			creditReport: {},
			checkHistories: [],
			checkRecords: [],
			processStatus: {},
			showEditBtn: false,
			editable: false,
			dataComplete: false,
			detailData: {}
		}
	},
	components: {
		UserInfo,
		LoanInfoDetail,
		InsurInfo,
		CreateInfoDetail,
		PersonInfoDetail,
		VehicleInfo,
		FinancialInfo,
		ApplicationInfo,
		CreditFileInfo,
		LoanDetail,
		LoanRecord,
		AuditRecord
	},
	created() {
		this.$nextTick(() => {
			this.applyInfoInit()
		})
	},
	methods: {
		applyInfoInit() {
			apiIpfApplyDetail(this.$route.query.id, false)
				.then((res) => {
					this.userInfo = {
						createSource: res.createSource ? res.createSource : '',
						createTime: res.createTime ? res.createTime : '',
						creatorId: res.creatorId ? res.creatorId : '',
						creatorName: res.creatorName ? res.creatorName : '',
						customerId: res.customerId ? res.customerId : '',
						statusCode: res.processStatus ? res.processStatus.statusCode : null,
						caseId: res.caseId ? res.caseId : '',
						casePrefix: res.casePrefix ? res.casePrefix : ''
					}
					this.loanInfo = res.loanInfo
						? res.loanInfo
						: {
								loanId: null,
								applyAmount: null,
								loanProcessingFee: null,
								creditLifeInsuranceFee: null,
								lessLoanBalance: null,
								crbAmount: null,
								transferAmount: null,
								gpsInstallAmount: null,
								gpsMonthAmount: null,
								firstMonthDeduce: null,
								mortgageRegistrationAmount: null,
								amountPayable: null,
								interestRate: null,
								installmentsNumbers: null,
								firstMonthRepay: null,
								otherMonthRepay: null
						  }
					this.insuranceData = res.loanInsuranceInfos
						? res.loanInsuranceInfos.map((item) => {
								item.insuranceInterest = item.insuranceInterest * 100
								return item
						  })
						: [
								{
									hasInsurance: 0
								}
						  ]
					this.createInfo = {
						cityCode: res.cityCode,
						cityName: res.cityName,
						storeId: res.storeId,
						storeName: res.storeName,
						salesmanId: res.salesmanId,
						salesmanName: res.salesmanName
					}
					this.personalInfo = res.personalInfo ? res.personalInfo : {}
					this.financeInfo = res.financeInfo ? res.financeInfo : {}
					this.loanCarInfos = res.loanCarInfos ? res.loanCarInfos : []
					this.disburseInfo = res.disburseInfo ? res.disburseInfo : null
					this.loanApplyInfo = res.loanApplyInfo ? res.loanApplyInfo : {}
					this.creditReport = res.creditReport ? res.creditReport : {}
					this.checkHistories = res.checkHistories ? res.checkHistories : []
					this.checkRecords = res.checkRecords ? res.checkRecords : []
					this.processStatus = res.processStatus ? res.processStatus : {}
					this.dataComplete = true
					if (this.processStatus.currentUserCanProcess) {
						this.processStatus.processNode.id === 'ipf_info_4' ? (this.editable = true) : ''
					}
					this.detailData = res
					this.$emit('getCaseDetailsData', { ...res })
				})
				.catch((err) => {
					console.log(err)
					if (err.code === -1 && err.data === null) {
						this.$emit('getCaseDetailsData', null)
					}
				})
		},
		setInsuranceCompleted(val) {
			this.$emit('setInsuranceCompleted', val)
		}
	}
}
</script>
<style lang="less">
.case-details {
	.ant-input-number,
	.ant-select,
	.ant-input {
		width: 200px;
	}
	.ant-pagination-options {
		.ant-select {
			width: auto;
		}
	}
}
</style>
