import { render, staticRenderFns } from "./renewalDetail.vue?vue&type=template&id=87875d12&scoped=true"
import script from "./renewalDetail.vue?vue&type=script&lang=js"
export * from "./renewalDetail.vue?vue&type=script&lang=js"
import style0 from "./renewalDetail.vue?vue&type=style&index=0&id=87875d12&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87875d12",
  null
  
)

export default component.exports